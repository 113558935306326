import "../styles/scss/app.scss";
import "react-image-crop/src/ReactCrop.scss";
import "tailwindcss/tailwind.css";

import React, { useEffect } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import MyThemeProvider from "@/components/MyThemeProvider";
import AppProvider from "@/contexts/AppProvider";
import Page from "@/components/Page";
import * as gtag from "@/lib/gtag";

export default function MyApp(props: any) {
  const { Component, pageProps } = props;

  const router = useRouter();
  useEffect(() => {
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }

    const handleRouteChange = (path: any) => {
      gtag.pageview(path);
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />

        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        <meta charSet="utf-8" />

        <base href={`${process.env.FRONTEND_URL}`} target="_top" />

        <link rel="icon" href="/commutty_it.svg" type="image/svg+xml" />

        <link rel="mask-icon" href="/commutty_it.svg" />

        <link rel="apple-touch-icon" href="/commutty_it.svg" type="image/svg+xml" />

        <link rel="shortcut icon" href="/favicon.ico" />

        <meta name="theme-color" content="#ff6a14" />

        <meta name="msapplication-TileColor" content="#ffffff" />
      </Head>

      <AppProvider>
        <MyThemeProvider>
          <Page>
            <Component {...pageProps} />
          </Page>
        </MyThemeProvider>
      </AppProvider>
    </>
  );
}
