import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import NextNprogress from "nextjs-progressbar";
import React from "react";
import { useAppState } from "@/contexts/AppProvider";
import { useRouter } from "next/router";

export default function MyThemeProvider({ children }: any) {
  const getDesignTokens: any = (mode: any) => ({
    palette: {
      mode,
      ...(mode === "light"
        ? {
            // palette values for light mode
            primary: { main: "#393939" },
            secondary: { main: "#ff6a14" },
            warning: { main: "#ff8a65" },
            info: { main: "#ffffff" },
          }
        : {
            // palette values for dark mode
            primary: { main: "#ffffff" },
            secondary: { main: "#ff8a46" },
            warning: { main: "#ff8a65" },
            info: { main: "#ffffff" },
          }),
    },

    typography: {
      button: {
        textTransform: "none",
      },
    },
  });

  const router = useRouter();
  const pathname = router.pathname;
  const [state] = useAppState();
  const isDarkMode = state.darkMode;
  const forceLightMode = ["/ads/about"];
  const isLightModeForced = forceLightMode.includes(pathname);

  const theme = React.useMemo(() => {
    const mode = isLightModeForced || !isDarkMode ? "light" : "dark";
    return createTheme(getDesignTokens(mode));
  }, [isDarkMode, isLightModeForced]);
  return (
    <ThemeProvider theme={theme}>
      <NextNprogress
        startPosition={0.3}
        stopDelayMs={200}
        height={1}
        color="primary"
        showOnShallow={true}
      />

      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
