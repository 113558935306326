import React, { useEffect, useState, useContext, createContext } from "react";
import Router from "next/router";
import { useAppState } from "@/contexts/AppProvider";
import { withRouter, useRouter } from "next/router";
import Header from "./Header";
import Footer from "./Footer";

import { IconButton } from "@mui/material";
import { DarkMode, LightMode } from "@mui/icons-material";
import * as gtag from "@/lib/gtag";
import GoogleLoginModal from "./GoogleLoginModal";
import { ErrorField } from "../features/parts/ErrorField";

const ErrorMessageContext = createContext({} as {
  errorMessage: string|undefined,
  setErrorMessage: Function
});

const Page = (props: any) => {
  const [state, dispatch] = useAppState();
  Router.events.on("routeChangeComplete", () => {
    window &&
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "auto",
      });
  });

  const router = useRouter();
  const pathname = router.pathname;
  const noPaddPage = ["/404", "/500"];

  const noHeader = ["/onboarding"];
  const noFooter = [
    "/articles/[slug]/edit",
    "/books/[slug]/edit",
    "/onboarding",
    "/[username]/articles/[slug]",
  ];
  const forceLightMode = ["/ads/about"];
  const noOverflowX = ["/onboarding"];
  const withDarkModeButton = ["/"];
  const withoutDarkModeButton = ["/ads/about"];
  const noScrollBarPage = ["/"];
  const isNoPadd = noPaddPage.includes(pathname) && state.mobile;
  const isNoFooter = noFooter.includes(pathname);
  const isNoHeader = noHeader.includes(pathname);
  const isNoScrollBar = noScrollBarPage.includes(pathname);
  const isDarkModeButton = withDarkModeButton.includes(pathname);
  const isNoOverFlowX = noOverflowX.includes(pathname);
  const isLightModeForced = forceLightMode.includes(pathname);
  const isNoDarkModeButton = withoutDarkModeButton.includes(pathname);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  //最上位のhtmlタグでdark classをコントロールする
  function applyTheme() {
    var storageKey = "theme";
    var classNameDark = "dark";
    var classNameLight = "light";

    function setClassOnDocumentBody() {
      var html = document.getElementsByTagName("html")[0];
      html.classList.add(classNameDark);
    }
    function deleteClassOnDocumentBody() {
      var html = document.getElementsByTagName("html")[0];
      html.classList.remove(classNameDark);
    }

    var preferDarkQuery = "(prefers-color-scheme: dark)";
    var mql = window.matchMedia(preferDarkQuery);
    var supportsColorSchemeQuery = mql.media === preferDarkQuery;
    var localStorageTheme = null;
    try {
      localStorageTheme = localStorage.getItem(storageKey);
    } catch (err) {}
    var localStorageExists = localStorageTheme !== null;

    if (localStorageExists) {
      if (localStorageTheme === classNameDark) {
        setClassOnDocumentBody();
      } else {
        deleteClassOnDocumentBody();
      }
    } else if (supportsColorSchemeQuery) {
      setClassOnDocumentBody();
      localStorage.setItem(storageKey, classNameDark);
    } else {
      deleteClassOnDocumentBody();
      localStorage.setItem(storageKey, classNameLight);
    }
  }
  //最上位のhtmlタグでdark classをコントロールする
  function forceLight() {
    var classNameDark = "dark";

    function deleteClassOnDocumentBody() {
      var html = document.getElementsByTagName("html")[0];
      html.classList.remove(classNameDark);
    }
    deleteClassOnDocumentBody();
  }
  const [switched, setSwitched] = useState(0);
  useEffect(() => {
    if (!isLightModeForced) {
      applyTheme();
      if (switched > 1) {
        gtag.event({
          action: state.darkMode ? "switch_to_darkmode" : "switch_to_lightmode",
          category: "ui",
          label: "customize_ui",
        });
      } else {
        setSwitched(switched + 1);
      }
    }
  }, [state.darkMode]);
  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      if (isLightModeForced) {
        forceLight();
      } else {
        applyTheme();
      }
    }
  }, [isLightModeForced]);

  function themeHandler() {
    if (state.darkMode) {
      localStorage.setItem("theme", "light");
      dispatch({ type: "toggleDarkMode", payload: false });
    } else {
      localStorage.setItem("theme", "dark");
      dispatch({ type: "toggleDarkMode", payload: true });
    }
  }
  const handleClose = () => {
    dispatch({ type: "setLoginModal", payload: false });
  };

  return (
    <>
      <div
        className={
          "page bg-white dark:bg-gray-800 text-gray-900 dark:text-white" +
          (isNoScrollBar ? " no-scroll-bar" : "")
        }
      >
        {!isNoHeader && (
          <Header
            isDarkMode={state.darkMode}
            themeHandler={themeHandler}
            isNoDarkModeButton={isNoDarkModeButton}
          />
        )}

        <div
          style={{
            paddingTop: isNoPadd ? "0px" : "60px",
            minHeight: "100vh",
          }}
          className={
            "border-solid border-b border-blue-50 dark:border-gray-700 " +
            (isNoOverFlowX ? "overflow-x-hidden" : "")
          }
        >
          {errorMessage &&(<ErrorField errorMessage={errorMessage}/>)}
          {props.children}
        </div>
        {!isNoFooter && (
          <ErrorMessageContext.Provider value={{ errorMessage, setErrorMessage }}>
            <Footer />
          </ErrorMessageContext.Provider>
        )
        }
        {isDarkModeButton && (
          <IconButton
            style={{
              position: "fixed",
              bottom: "5vh",
              right: "5vh",
              zIndex: "99",
            }}
            className="light-shadow hovering-button-white md:hidden"
            onClick={() => {
              themeHandler();
            }}
          >
            {state.darkMode ? <LightMode /> : <DarkMode />}
          </IconButton>
        )}
        <ErrorMessageContext.Provider value={{ errorMessage, setErrorMessage }}>
          <GoogleLoginModal open={state.loginModal} onClose={handleClose} />
        </ErrorMessageContext.Provider>
      </div>
    </>
  );
};

export const useErrorMessageContext = () => useContext(ErrorMessageContext);
export default withRouter(Page);
