const msg = `Welcome to Commutty IT!!`;

export default {
  SIDEADS: "Show Ads beside the article",
  ARTICLE: "Article",
  BOOK: "Book",
  CF1: "Instant Code Execution",
  CF2: "Run Code with one-click right in browser",
  CF3: "You can edit and run code without any set-up, and check or save outputs.",
  CF4: "Now 13 programming languages are supported.",
  ED1: "Handful Editor",
  ED2: "Share your knowledge in the best way",
  ED3: "In Commutty IT, you can write an article adding markdown cell, code cell or ads cell, in the same layout as the public page of it.",
  ED4: "You can also post directly with your markdown / jupyter notebook file on your local environment or your github repository.",
  SERVICEDESCRIPTION:
    "Commutty IT is a blog service where engineers can easily monetize.",
  TOPPAGEMSG: "Commutty IT is a blog service where engineers can easily monetize.",
  INTEGRATEGITHUB: "Integrate with Github",
  DISSOLVEINTEGRATION: "Dissolve integration",
  LAUNCHING: "Launching... (maybe around 30s)",
  UNAVAILABLESLUG: "This ID is unavailable",
  MSG: msg,
  LOGLOGIN: "Login",
  DISPLAYNAME: "Display name",
  ACCOUNTID: "Account ID (can't be changed later)",
  PROFILE: "Profile",
  DOMAINALREADYEXISTS: "This user id is already used.",
  NEXT: "Next",
  CANCEL: "Cancel",
  FOLLOWTAGS: "Follow tags",
  RECOMMENDCONTENTS:
    "Commutty IT recommend contents based on the tags you follow.",
  REGISTER: "Register",
  BACK: "Back",
  LOGOUT: "Logout",
  ACCOUNT: "Account",
  SETTINGS: "Settings",
  NOTIFICATION: "Notification",
  COMMENT: "Comment",
  LIKE: "Like",
  FOLLOW: "Follow",
  SUPERCOMMENT: "Super comment",
  NONOTIFICATIONS: "No notifications.",
  YOUR: "your",
  SELECT: "Select",
  CONFIRM: "Confirm",
  EDIT: "Edit",
  DELETE: "Delete",
  PARMANENTLYDELETE: "Are you sure you want to parmanently delete?",
  SAVE: "Save",
  POST: "Post",
  COPIED: "Copied",
  LOGINTOCOMMENT: "You must Login to post a comment.",
  FAILEDTOPOST: "Comment failed to post",
  AUTHENTICATIONFAILED: "Authentication failed",
  WRITECOMMENT: "Please write comment",
  POSTSUPERCOMMENT: "Post super comment",
  LINKCOPIED: "Link copied",
  INPUTKEYWORDS: "Input keywords...",
  ERROR: "Error!",
  HOWTOENJOY: "How to enjoy Commutty IT",
  HOWTOUSE: "How to use",
  AFTERLOGGEDIN: "After logged in, you can enjoy all features.",
  WRITENEW: "Add articles and books",
  LIKESANDCOMMENTS: "Likes and comments",
  LIKES: "Likes",
  PAIDCONTENTS: "",
  PLEASELOGIN_EN: "Please accept ",
  PLEASELOGIN_JA: "",
  TERMS: "Terms",
  AND: " and ",
  OR: " or ",
  PRIVACYPOLICIES: "Privacy policies",
  MONTHAGO: "months ago",
  DAYSAGO: "days ago",
  HOURSAGO: "hours ago",
  NOW: "now",
  CONTACT: "Contact",
  SUBMIT: "Submit",
  TRANSACTIONACT: "Specified Commercial Transaction Act",
  YOUROPINION: "Please write contents",
  WRITETITLE: "Please write title",
  WRITEDESCRIPTION: "Please write description",
  CHANGE: "Change",
  DROPORCLICK_1: "Please drop ",
  DROPORCLICK_2: " or click here",
  PURCHASE: "Purchase",
  PURCHASED: "Purchased",
  AMOUNTANDMESSAGEPUBLIC: "Your amount and message will be public...",
  REPLAY: "Replay",
  PUBLIC: "Public",
  DRAFT: "Draft",
  PUBLISH: "Publish",
  PUBLISHED: "Published",
  DRAFTPREVIEW: "Previewing your draft.",
  READNOW: "Read now",
  UPDATE: "Update",
  BOOKPURCHASENOTE: "You can purchase the book or each article.",
  ADDARTICLE: "New Article",
  ADDARTICLETOBOOK: "Add an article",
  ADDBOOK: "New Book",
  ADDNEW: "Add new",
  NEEDARTICLETITLE: "Needs articles's title.",
  NEEDDESCRIPTION: "Needs article's description.",
  NEEDARTICLEBODY: "Needs article's body.",
  NEEDARTICLETAG: "Needs article's tags.",
  NEEDBOOKTITLE: "Needs book's title.",
  NEEDBOOKDESCRIPTION: "Needs book's description.",
  NEEDBOOKTAG: "Needs book's tag",
  CHECKNETWORKCONNECTION: "Check your network connection.",
  ARTICLEBOOK: "Publish your articles in a book.",
  PAIDARTICLEPAIDBOOK:
    "The price of each paid article is automatically determined by the price of the book and the number of paid articles.",
  TITLELIMIT: "The title needs to be no more than 100 characters.",
  PAIDARTICLEORBOOK: "Paid article or book",
  CONNECTING: "Connecting... It may take more than 10 seconds.",
  CONNECTIONFAILED: "Connection failed. Please redo or reload.",
  ONLYAUTHER: "Only the auther can edit.",
  SAVEDRAFT: "Save as draft",
  SAVING: "Saving",
  SAVED: "Saved",
  SAVEDSUCCESSFULLY: "Saved successfully!!",
  PUBLISHEDSUCCESSFULLY: "Published successfully!!",
  PUBLISHING: "Publishing",
  LATESTVERSION: "Latest version",
  FILENAMELIMIT: "File name needs to be no more than 100 characters.",
  LEAVEPAGE: "Are you sure you want to leave page without saving?",
  EDITING: "Editing",
  SELECTLANGUAGE: "Select language",
  IMPORTFILE: "Import file",
  YOUCANIMPORT_EN: "You can import",
  YOUCANIMPORT_JA: "",
  YOUCANADD_EN: "You can add ",
  YOUCANADD_JA: "",
  LOCAL: "Local",
  ADD: "Add",
  ADDING: "Adding...",
  SELECTEDFILE: "Selected file",
  ARTICLEPUBLICLYAVAILABLE: "This article is publicly available now.",
  ARTICLEPUBLICLYUNAVAILABLE: "This article is private now.",
  BOOKPUBLICLYAVAILABLE: "This book is publicly available now.",
  BOOKPUBLICLYUNAVAILABLE: "This book is private now.",
  WRITEMARKDOWN: "Write in markdown...",
  IMPORTIMAGE: "Import an image",
  IMPORTIMAGE_EXPLANATION: "Drop an image or click here",
  ADDBLANK: "Add blank",
  ADDMYARTICLE: "Add an exisiting article",
  FREEBOOKARTICLE: "This article is for free.",
  CANCHANGELANGUAGE: "You can change the programming language in the settings.",
  FAVORITE: "Favorite",
  LATEST: "Latest",
  ALL: "All",
  SHOW: "Appearance",
  SUCCEEDED: "Succeeded",
  FAILED: "Failed",
  DARKMODE: "Dark mode",
  INTRODUCTION: "Show introduction in the top page",
  EDITORTUTRIAL:
    "Code in markdown cells is non-executable, code in code cell is executable",

  SHAREYOURKNOWLEDGE: "Share your knowledge",
  REQUESTSUCCEEDED: "Request succeeded",
  REQUESTFAILED: "Request failed",
  TOTALSALES: "Total Sales",
  CURRENCY: "$",
  RECEIVEDSALES: "Amount recieved",
  THISMOUNTHSALES: "This mount's sales",
  RECEIVABLESALES: "Receivable amount",
  WHENICANRECIEVE: "Payment threshold for your account is $10",

  D_NOTIFICATION_LIKE:           "liked your article \"%s\".",
  D_NOTIFICATION_COMMENT:        "commented on your article \"%s\".",
  D_NOTIFICATION_TIP:            "gave a tip for your article \"%s\".",
  D_NOTIFICATION_SUPER_COMMENT:  "has made a super comment on your article \"%s\".",
  D_NOTIFICATION_PURCHASED_NOTE: "",
  D_NOTIFICATION_PURCHASED_BOX:  "",
  D_NOTIFICATION_REPLY:          "replied to your comment on your article \"%s\"",
  D_NOTIFICATION_FOLLOWD_BY:     "followed you",
  D_NOTIFICATION_FROM_OPERATION: "has informed us that\"%s\"",

  E_EXCEEDING_THE_CHARACTER_COUNT: "The %s should be no more than %d characters.",
  E_CAN_NOT_BE_LEFT_BLANK: "%s cannot be left blank.",
  E_UPLOAD_FILESIZE_OVER: 'The file cannot be uploaded because the file size exceeds the upper limit.',

  python: `print("${msg}")`,
  r: `print("${msg}")`,
  julia: `println("${msg}")`,
  go: `import ("fmt")
func main() {
  fmt.Println("${msg}")
}
main()`,
  javascript: `console.log("${msg}")`,
  typescript: `console.log("${msg}")`,
  cpp: `#include <iostream>

std::cout << "${msg}" << std::endl;`,
  csharp: `Console.WriteLine("${msg}")`,
  fsharp: `printfn "${msg}"`,
  haskell: `putStrLn "${msg}"`,
  ruby: `print "${msg}"`,
  java: `System.out.println("${msg}")`,
  kotlin: `println("${msg}")`,
  scala: `println("${msg}")`,
  rust: `println!("${msg}")`,
  c: `printf("${msg}");`,
};
