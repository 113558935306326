import { AddCircle, Notifications, Search } from "@mui/icons-material";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import {
  AppBar,
  Badge,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
} from "@mui/material";
import axios from "axios";
import { auth } from "@/utils/firebase";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import Avatar from "./Avatar";
import { useAppState } from "@/contexts/AppProvider";
import { getLocale } from "@/lang/get-locale";
import GetNewSlug from "./GetNewSlug";
import Image from "next/image";

import { NotificationList } from "./header_components/NotificationList"

const PrimarySearchAppBar = ({
  isDarkMode,
  themeHandler,
  isNoDarkModeButton = false,
}: any) => {
  const { t, locale } = getLocale();
  const router = useRouter();
  const [state, dispatch] = useAppState();
  //login modal control
  const handleClickOpen = () => {
    dispatch({ type: "setLoginModal", payload: true });
  };

  const userSlug = state.userInfo ? state.userInfo.user.slug : "";
  //以下アカウントメニュー
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const isMenuOpen = Boolean(anchorEl);
  const menuId = "account-menu";
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleProfileMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      style={{
        zIndex: "9999",
        top: "45px",
      }}
    >
      <MenuItem
        onClick={() => {
          router.push(`/${userSlug}`);
          handleMenuClose();
        }}
        className="border-solid border-b border-blue-50 dark:border-gray-700"
      >
        <b>{t.ACCOUNT}</b>
      </MenuItem>
      {locale === "ja" && (
        <MenuItem
          onClick={() => {
            router.push("/dashboard");
            handleMenuClose();
          }}
          className="border-solid border-b border-blue-50 dark:border-gray-700"
        >
          <b>ダッシュボード</b>
        </MenuItem>
      )}
      <MenuItem
        onClick={() => {
          router.push("/settings");
          handleMenuClose();
        }}
        className="border-solid border-b border-blue-50 dark:border-gray-700"
      >
        <b>{t.SETTINGS}</b>
      </MenuItem>

      <MenuItem
        onClick={() => {
          handleMenuClose();
          auth.signOut().then((result) => {
            console.log("logout succeeded");
            dispatch({ type: "setLoginModal", payload: false });

            router.reload();
          });
        }}
      >
        <b>{t.LOGOUT}</b>
      </MenuItem>
    </Menu>
  );
  //以下通知
  const [ntfs, setNtfs] = useState<any>([]); //notifications
  const [nOfNtfs, setNOfNtfs] = useState<any>(0);

  //get all notifications if user exists every 30 s
  useEffect(() => {
    let unmounted = false;
    const getNtfs = () =>
      auth.onAuthStateChanged((user) => {
        if (user) {
          user
            .getIdToken(true)
            .then((idToken) => {
              axios
                .get(`${process.env.BACKEND_URL}/v1/notification/`, {
                  headers: {
                    "content-type": "application/json",
                    Authorization: `JWT ${idToken}`,
                  },
                })
                .then((r) => {
                  !unmounted && setNtfs(r.data.reverse());
                  const readNum = r.data.reduce((prev: any, item: any) => {
                    return prev + (item.read ? 0 : 1);
                  }, 0);
                  !unmounted && setNOfNtfs(readNum);
                })
                .catch((e) => console.log(e));
            })
            .catch((e) => console.log(e));
        }
      });
    getNtfs();
    const interval = setInterval(() => {
      getNtfs();
    }, 30000);
    return () => {
      clearInterval(interval);
      unmounted = true;
    };
  }, []);
  const [anchorElNtf, setAnchorElNtf] = useState<any>(null);
  const isNtfMenuOpen = Boolean(anchorElNtf);
  const ntfmenuId = "notification";
  const handleNtfMenuClose = () => {
    setAnchorElNtf(null);
  };
  const handleNtfMenuOpen = (event: any) => {
    setAnchorElNtf(event.currentTarget);
  };

  /** mark all notifications as read */
  const markAllAsRead = () => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          .then((idToken) => {
            axios
              .post(
                `${process.env.BACKEND_URL}/v1/notification/read`,
                {},
                {
                  headers: {
                    "content-type": "application/json",
                    Authorization: `JWT ${idToken}`,
                  },
                }
              )
              .then((r) => {
                console.log(r.data);
              })
              .catch((e) => console.log(e));
          })
          .catch((e) => console.log(e));
      }
    });
  };

  // ads
  const isAdsPage =
    router.asPath.split("/").length > 0 && router.asPath.split("/")[1] == "ads";

  return (
    <div className="grow">
      <AppBar
        style={{
          zIndex: "99",
          boxShadow: "none",
          maxWidth: "100vw",
        }}
        className="bg-white dark:bg-gray-800 text-gray-900 dark:text-white border-solid border-b border-blue-50 dark:border-gray-700"
      >
        <Toolbar
          style={{ height: "60px !important" }}
          className="bg-white dark:bg-gray-800"
        >
          <Grid container>
            <Grid item xs={12} className="p-0 flex items-center">
              <span className="pr-1">
                <Link
                  scroll={false}
                  href={isAdsPage ? "/ads/about" : "/"}
                  className="logo-color">

                  <div
                    style={{
                      height: "35px",
                      lineHeight: "35px",
                    }}
                  >
                    <Image
                      src={isAdsPage ? "/ads.svg" : "/commutty_it.svg"}
                      alt="Ccmmutty logo"
                      width={35}
                      height={35}
                      priority
                      className="align-middle"
                    />
                  </div>

                </Link>
              </span>

              <Link
                scroll={false}
                href={isAdsPage ? "/ads/about" : "/"}
                className={
                  "block font-bold " +
                  (isAdsPage ? "text-base md:text-xl " : "text-xl")
                }>
                Commutty IT{isAdsPage && " Ads"}

              </Link>

              <div className="grow" />
              {!isNoDarkModeButton && (
                <div className="hidden md:block">
                  <IconButton
                    style={{ padding: "8px" }}
                    color="inherit"
                    onClick={() => {
                      themeHandler();
                    }}
                  >
                    {isDarkMode ? <LightModeIcon /> : <DarkModeIcon />}
                  </IconButton>
                </div>
              )}

              <span className="md:px-2">
                <Link scroll={false} href="/search">

                  <IconButton
                    aria-label="search"
                    color="inherit"
                    style={{ padding: "6px" }}
                  >
                    <Search />
                  </IconButton>

                </Link>
              </span>
              {state.fetched ? (
                <>
                  {state.userInfo ? (
                    <>
                      <span className="px-2 md:px-1">
                        <IconButton
                          id={ntfmenuId}
                          onClick={(e) => {
                            handleNtfMenuOpen(e);
                            nOfNtfs > 0 && markAllAsRead();
                            setNOfNtfs(0);
                          }}
                          style={{ cursor: "pointer", padding: "6px" }}
                          color="inherit"
                        >
                          {nOfNtfs > 0 ? (
                            <>
                              <Badge
                                badgeContent={
                                  <b style={{ color: "white" }}>{nOfNtfs}</b>
                                }
                                color="secondary"
                              >
                                <Notifications />
                              </Badge>
                            </>
                          ) : (
                            <>
                              <Notifications />
                            </>
                          )}
                        </IconButton>
                      </span>

                      <span className="px-2 md:px-3">
                        <span
                          id={menuId}
                          onClick={handleProfileMenuOpen}
                          style={{ cursor: "pointer" }}
                        >
                          <Avatar
                            size={35}
                            src={state.userInfo && state.userInfo.user.minicdn}
                            name={
                              state.userInfo && state.userInfo.user.username
                            }
                          />
                        </span>
                      </span>
                    </>
                  ) : (
                    <span className="px-2 md:px-3">
                      <Button
                        color="secondary"
                        variant="outlined"
                        onClick={handleClickOpen}
                      >
                        <b>Login</b>
                      </Button>
                    </span>
                  )}
                </>
              ) : (
                <div
                  style={{
                    width: "100px",
                    height: "60px",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                  className="text-center"
                >
                  <CircularProgress
                    style={{ color: "#f1f5f9", width: "25px", height: "25px" }}
                  />
                </div>
              )}

              <IconButton
                style={{ padding: "4px" }}
                className="text-primary dark:text-primary-light"
                onClick={() => {
                  !state.userInfo
                    ? dispatch({ type: "setLoginModal", payload: true })
                    : router.push(`/articles/${GetNewSlug()}/edit`);
                }}
              >
                <AddCircle
                  style={{
                    width: "40px",
                    height: "40px",
                  }}
                />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {renderMenu}

      <NotificationList 
        anchorElNtf={anchorElNtf}
        ntfmenuId={ntfmenuId}
        isNtfMenuOpen={isNtfMenuOpen}
        handleNtfMenuClose={handleNtfMenuClose}
        ntfs={ntfs}
       />
    </div>
  );
};

export default PrimarySearchAppBar;
