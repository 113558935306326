import React, { useState } from "react";
import Alert from '@mui/material/Alert';

type ErrorField = {
  errorMessage: string;
  margin?: number;
}

export const ErrorField = (props: ErrorField) => {
  const {errorMessage, margin = 2} = props;
  return (
    <div>
      <Alert severity='warning' sx={{ m:margin }} variant="outlined">
        {errorMessage}
      </ Alert>
    </div>
  );
}