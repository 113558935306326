import { Avatar } from "@mui/material";

const colors = [
  "red",
  "green",
  "orange",
  "purple",
  "blue",
  "cyan",
  "teal",
  "indigo",
];
const NewAvatar = ({ size, src, name }: any) => {
  const color = name ? colors[name.length % colors.length] : "grey";

  const style = {
    backgroundColor: src ? color : color,
    width: size,
    height: size,
  };
  return src ? (
    <Avatar
      src={src.replace("cdn.apollon.ai", "cdn.magicode.io")}
      aria-label="author-name"
      style={style}
    />
  ) : (
    <Avatar aria-label="author-name" style={style}>
      <b
        style={{
          fontSize: `${Math.floor(size * 0.6)}px`,
        }}
      >
        {name ? name.charAt(0) : ""}
      </b>
    </Avatar>
  );
};

export default NewAvatar;
