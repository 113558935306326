import { Menu, MenuItem } from "@mui/material";
import Link from "next/link";
import { useRouter } from "next/router";
import { sprintf } from "sprintf-js";

import Avatar from "../Avatar";
import { getLocale } from "@/lang/get-locale";

export function NotificationList(props) {

  const router = useRouter();
  const { t } = getLocale();

  const typeOfNotification : Array<{id : number, type : string , displayStatement : string}> = [  
    // indexは0始まりのためidと1ずれているので注意
    {id : 1, type : "いいね"           , displayStatement : t.D_NOTIFICATION_LIKE},
    {id : 2, type : "コメント"         , displayStatement : t.D_NOTIFICATION_COMMENT},
    {id : 3, type : "投げ銭"           , displayStatement : t.D_NOTIFICATION_TIP},            // 投げ銭の口が現状存在しない
    {id : 4, type : "スーパーコメント" , displayStatement : t.D_NOTIFICATION_SUPER_COMMENT},
    {id : 5, type : "Noteの購入"       , displayStatement : t.D_NOTIFICATION_PURCHASED_NOTE}, // 有料記事機能は廃止済みのため未使用
    {id : 6, type : "Boxの購入"        , displayStatement : t.D_NOTIFICATION_PURCHASED_BOX},  // 有料本機能は廃止済みのため未使用
    {id : 7, type : "返信"             , displayStatement : t.D_NOTIFICATION_REPLY},
    {id : 8, type : "フォロー"         , displayStatement : t.D_NOTIFICATION_FOLLOWD_BY},
    {id : 9, type : "運営より"         , displayStatement : t.D_NOTIFICATION_FROM_OPERATION}
  ];
  
  const idToTypeOfNotificationIdx = (id : number) : number => {
    return id - 1 ;
  };

  return (
    <div>
    <Menu
    anchorEl={props.anchorElNtf}
    anchorOrigin={{ vertical: "top", horizontal: "right" }}
    id={props.ntfmenuId}
    keepMounted
    transformOrigin={{ vertical: "top", horizontal: "right" }}
    open={props.isNtfMenuOpen}
    onClose={props.handleNtfMenuClose}
    style={{
      zIndex: "9999",
      top: "45px",
      maxHeight: "90vh",
      overflowY: "auto",
    }}
    >
    {props.ntfs.length === 0 && (
      <MenuItem style={{ width: "250px" }}>
        <div style={{ whiteSpace: "normal" }} className="text-center">
          {t.NONOTIFICATIONS}
        </div>
      </MenuItem>
    )}
    {props.ntfs.length > 0 &&
      props.ntfs.map((item, index) => {
        
        const fromUser   : any     = item.from_user; // TODO 中身の整理
        const kindName   : string  = item.kind.name;
        const kindId     : number  = item.kind.id;
        const note       : any     = item.note;      // TODO 中身の整理
        const read       : boolean = item.read;
        const noteIsOpen : boolean = note && ((note.box && note.box.is_open) || (!note.box && note.is_open));

        const idx = idToTypeOfNotificationIdx(kindId);
        let displayNotification = "メッセージ取得エラー";
        switch (kindId) {
          case 1 : // いいね
          case 2 : // コメント
          case 3 : // 投げ銭
          case 4 : // スーパーコメント
          case 7 : // 返信
          case 9 : // 運営より
          displayNotification = sprintf(typeOfNotification[idx]['displayStatement'], note.title);
          break;

          case 8 : // フォロー
          displayNotification = typeOfNotification[idx]['displayStatement'];
          break;

          default :
          break;
        }

        return (
          <MenuItem
            key={`ntf-${index}`}
            style={{
              verticalAlign: "middle",
              alignItems: "center",
              width: "250px",
            }}
            className={
              (!read ? "bg-orange-50 dark:bg-gray-500 " : "") +
              "border-solid border-b border-blue-50 dark:border-gray-700 flex"
            }
            onClick={() => {
              props.handleNtfMenuClose();
            }}
          >
            {/* from user info */}

            <div>
              <Link href={`/${fromUser.slug}`}>

                <Avatar
                  size={28}
                  src={fromUser.minicdn}
                  name={fromUser.username}
                />

              </Link>
            </div>

            <div style={{ whiteSpace: "normal", paddingLeft: "12px" }}>
              <Link href={`/${fromUser.slug}`}>

                <b>{fromUser.username}</b>

              </Link>

              <span
                onClick={() => {
                  note &&
                    router.push(
                      noteIsOpen
                        ? `/${note.author.slug}/articles/${note.slug}${
                            [
                              "コメント",
                              "返信",
                              "スーパーコメント",
                            ].includes(kindName)
                              ? "#go-to-comment"
                              : ""
                          }`
                        : `/articles/${note.slug}/preview`
                    );
                }}
                style={{ cursor: "pointer" }}
              >
                {<> {displayNotification}</>}
              </span>
            </div>
          </MenuItem>
        );
      })}
    </Menu>

    </div>
  )
}
