import { Close } from "@mui/icons-material";
import { Dialog, IconButton } from "@mui/material";
import { auth } from "@/utils/firebase";
import Image from "next/image";
import GoogleButton from "react-google-button";
import { getLocale } from "@/lang/get-locale";
import { useAppState } from "@/contexts/AppProvider";
import {
  signInWithPopup,
  GoogleAuthProvider,
  TwitterAuthProvider,
  FacebookAuthProvider,
  GithubAuthProvider,
  EmailAuthProvider,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import { useErrorMessageContext } from "@/src/components/Page";

export default function SimpleDialog({ onClose, open }: any) {
  const { t } = getLocale();
  const [state, dispatch] = useAppState();
  const {errorMessage, setErrorMessage} = useErrorMessageContext();

  const loginFunc = (e: any) => {
    //https://stackoverflow.com/questions/63095393/firebase-signinwithpopup-give-me-blank-page-when-use-it-inside-onclick
    e.preventDefault();
    // ログインの永続化

    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        // この後は普通に今まで通り
        const googleProvider = new GoogleAuthProvider();
        googleProvider.addScope("email");
        auth.languageCode = "ja";
        googleProvider.setCustomParameters({
          login_hint: "user@example.com",
        });
        signInWithPopup(auth, googleProvider)
          .then(() => {
            onClose();
            // 正常終了のため空を保持する
            setErrorMessage('');
            dispatch({ type: "setRefresh" });
          })
          .catch(() => {
            setErrorMessage(t.CHECKNETWORKCONNECTION);
            onClose();
          });
      })
      .catch(() => {
        setErrorMessage(t.CHECKNETWORKCONNECTION);
        onClose();
      });
  };
  //↑Google用

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      className="flex justify-center"
    >
      <div
        className="px-3 py-3"
        style={{ width: "320px", position: "relative" }}
      >
        <div style={{ position: "absolute", right: "5px", top: "5px" }}>
          <IconButton onClick={handleClose} color="inherit">
            <Close />
          </IconButton>
        </div>
        <div className="text-center">
          <Image
            src="/commutty_it.svg"
            alt="Commutty IT Logo"
            width={70}
            height={70}
          />
          <h3>
            <b>Log in</b>
          </h3>
        </div>
        <div className="py-1 text-gray-500 dark:text-gray-200 text-center">
          <br />
          {t.AFTERLOGGEDIN}👉{" "}
          <a
            href="https://it.commutty.com/CommuttyIT/articles/d90b9c0127ae468288e34f54c675abab"
            target="_blank"
            rel="nofollow noopener noreferrer"
            style={{ color: "#ff6a14" }}
          >
            <b className="underline">{t.HOWTOENJOY}</b>
          </a>
          <br />
        </div>
        <div className="py-6">
          <GoogleButton
            label="Login with Google"
            onClick={loginFunc}
            style={{
              margin: "auto",
              backgroundColor: "#ff6a14",
              fontWeight: "600",
            }}
          />
        </div>
        <div className="text-gray-500 dark:text-gray-200 text-center">
          {t.PLEASELOGIN_EN}
          <a href="/terms" target="_blank" rel="nofollow noopener noreferrer">
            <b className="underline">{t.TERMS}</b>
          </a>
          {t.AND}
          <a href="/privacy" target="_blank" rel="nofollow noopener noreferrer">
            <b className="underline">{t.PRIVACYPOLICIES}</b>
          </a>
          {t.PLEASELOGIN_JA}
        </div>
      </div>
    </Dialog>
  );
}
